import React from 'react';


const PageNotFound = () => {

    return (
        <h1>PAGE NOT FOUND PAGE</h1>
    )
}

export default PageNotFound
