import React from 'react'
import { makeStyles } from '@material-ui/core/styles';


const CardList2 = ({ styles, bulletImg, title1, title2, dataArray1, dataArray2 }) => {

    const { backgroundColor, color, boxShadow, borderRadius } = styles

    const useStyles = makeStyles((theme) => ({
        section1List1: {
            marginTop: 50,
            marginBottom: 50
        },
        section1List1Container: {
            backgroundColor: backgroundColor,
            color: color,
            fontFamily: 'inter-regular',
            padding: '83px 40px 70px 40px',
            borderRadius: borderRadius,
            boxShadow: boxShadow,
            marginTop: 110

        },
        section1List1Title: {
            fontSize: '1.03rem',
            fontFamily: 'inter-bold',
            letterSpacing: '1.25px'
        },
        section1list1ItemContainer: {
            display: 'flex',
            marginTop: 41,
            alignItems: 'baseline',
            [theme.breakpoints.down('xs')]: {
                marginTop: 10
            }
        },
        section1List1Img: {
            width: 10,
            marginRight: 26
        },
        section1List1Items: {
            listStyle: 'none',
            lineHeight: 2,
            overflowWrap: 'anywhere'


        },
    }))

    const classes = useStyles()

    return (

        <div className={classes.section1List1Container}>
            <span className={classes.section1List1Title}>{title1}</span>
            <div className={classes.section1List1}>
                {dataArray1.map((item, idx) => (
                    <div className={classes.section1list1ItemContainer} key={idx}>
                        <img className={classes.section1List1Img} src={bulletImg} alt="icon" />
                        <p className={classes.section1List1Items}>{item}</p>
                    </div>
                ))}
            </div>
            <span className={classes.section1List1Title}>{title2}</span>
            <div className={classes.section1List1}>
                {dataArray2.map((item, idx) => (
                    <div className={classes.section1list1ItemContainer} key={idx}>
                        <img className={classes.section1List1Img} src={bulletImg} alt="icon" />
                        <p className={classes.section1List1Items}>{item}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CardList2