import React, { useState, useContext } from 'react'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { accountContext, userBalanceContext, setStakedContext, hasStakedContext } from '../context/UserInfoContext'


import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import ExitIcon from '../../resources/svgs/exit-button.svg'
import PoorYouHave from '../PoorYouHave/PoorYouHave'
import AmountToStake from '../AmountToStake/AmountToStake'

import stake from '../../services/stake';
import stakingContract from '../../contracts/staking';

import Lottie from "react-lottie";
import animationData from "../../resources/lotties/vampire.json";

const DialogBox = withStyles((theme) => ({
    paper: {
        [theme.breakpoints.up('lg')]: {
            width: '40%',
            padding: 30
        },
        [theme.breakpoints.down('lg')]: {
            width: '40%',
            padding: 30
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 30
        }
    }
}))(Dialog)

const useStyles = makeStyles((theme) => ({
    exitButton: {
        width: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },
    title: {
        marginTop: 30,
        fontFamily: 'mikado',
        fontSize: '1.2rem',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },
    graphicContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 50
    },
    graphic: {
        height: 'auto',
        width: '100%',
        maxWidth: 160,
    },
    mainSection: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    }
}))


const StakeDialog = ({ handleClose, openRequest }) => {

    const classes = useStyles()
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))
    const poorBalance = useContext(userBalanceContext)
    const setStaked = useContext(setStakedContext);
    const hasStaked = useContext(hasStakedContext);

    const [stakeAll, setStakeAll] = useState(false);
    const account = useContext(accountContext);

    const stakeAllHandler = () => {
        setStakeAll(!stakeAll)
        console.log('POOR BALANCE WHEN STAKE HIT', poorBalance)
    }

    const stakeOnClickHandler = async (amountToStake) => {
        if (parseFloat(poorBalance.replace(/\,/g, '')) >= parseFloat(amountToStake)) {
            console.log('Stake onclick handler', amountToStake, typeof (amountToStake))
            await stake(stakingContract, account, amountToStake);
            console.log('stake dialog', !hasStaked);
            setStaked(!hasStaked);
        }
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    let lottie;
    if (matches){
        lottie = <Lottie
            options={defaultOptions}
            height={160}
            width={250}
        />
    }else {
        lottie = <Lottie
            options={defaultOptions}
            height={250}
            width={350}
        />
    }


    return (
        <DialogBox fullScreen={fullscreen} onClose={() => handleClose('stake')} open={openRequest} >
            <IconButton disableRipple className={classes.exitButton} onClick={() => handleClose('stake')} disableTouchRipple>
                <img src={ExitIcon} alt="exit" />
            </IconButton>

            <div>
                <section className={classes.titleContainer}>
                    <span className={classes.title}>How many Poor/ETH LP tokens do you want to stake?</span>
                </section>

                <section className={classes.graphicContainer}>
                    {lottie}
                </section>
                <section className={classes.mainSection}>
                    <PoorYouHave stakeAll={stakeAll} balance={poorBalance} stakeAllHandler={stakeAllHandler} />
                    <AmountToStake stakeAllHandler={stakeAllHandler} balance={poorBalance} stakeAll={stakeAll} onClickHandler={stakeOnClickHandler} />
                </section>
            </div>

        </DialogBox >


    )
}

export default StakeDialog