import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import BigNumber from "bignumber.js";


const StakeButton = withStyles({
    root: {
        fontFamily: 'Inter-regular',
        backgroundColor: '#AF4C75',
        border: 'solid',
        borderColor: '#AF4C75',
        textTransform: 'capitalize',
        color: 'white',
        borderWidth: 2,
        width: '100%',
        height: 53,
        fontSize: '0.9rem',
        '&:hover': {
            backgroundColor: '#9B345F',
            borderColor: '#9B345F',

        }
    }
})(Button)

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 200,
        [theme.breakpoints.down('xs')]: {
            marginTop: 40,
            padding: 38,
            backgroundColor: '#00000008',
            borderRadius: 4,
        }
    },
    stakedTokens: {
        fontFamily: 'mikado',
        fontSize: '2.5rem',
        color: '#111111',
        border: 0,
        width: '100%',
        textAlign: 'center',
        outline: 'none',
        '&:-webkit-appearance': 'none',
        margin: 0,
        'input[type=number]': {
            '-moz-appearance': 'textfield' /* Firefox */
        }
    },
    stakedTokenText: {
        fontFamily: 'Inter-regular',
        fontSize: '0.9rem',
        color: '#AF4C75',
        marginTop: 20
    },
    stakeButton: {
        marginTop: 55,
        width: '90%',
        display: 'flex',
        justifyContent: 'center'
    }
}))

const AmountToStake = ({ balance, stakeAll, onClickHandler, stakeAllHandler }) => {

    const classes = useStyles()
    const [stakeAmount, setStakeAmount] = useState('')

    const handleOnChange = (event) => {

        let value = event.target.value
        // console.log('Value at handle on change', value, typeof(value))

        if (!value) {
            setStakeAmount('')
        }

        if (value) {
            setStakeAmount(value);
            if (stakeAll){
                stakeAllHandler();
            }
        }

    }

    return (
        <div className={classes.mainContainer}>
            <input className={classes.stakedTokens} value={stakeAll ? balance : stakeAmount} onChange={handleOnChange} type="string" placeholder="Enter" />
            <span className={classes.stakedTokenText}>
                Amount to stake
            </span>
            <div className={classes.stakeButton}>
                {console.log(balance, typeof (balance))}
                <StakeButton onClick={() => onClickHandler(stakeAll ? balance : stakeAmount)} variant="outlined" disableElevation>
                    Stake
                </StakeButton>
            </div>
        </div>
    )
}

export default AmountToStake