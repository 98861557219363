import React, { useContext } from 'react'
import { isConnectedContext } from '../../components/context/UserInfoContext'

import { makeStyles } from '@material-ui/core/styles';
import EllipseSep from '../../resources/svgs/ellipse-seperator.svg'
import Stake from '../../components/Stake/Stake';
import Harvest from '../../components/Harvest/Harvest';
import HarvestUnstakeButton from '../../components/Buttons/HarvestUnstakeButton'
import HeroSection from '../../components/HeroSection/HeroSection'
import { Redirect } from "react-router-dom";
import { connectStatus, routes } from '../../utils/constants'


const useStyles = makeStyles((theme) => ({
    mainContainer: {
        textAlign: 'center'
    },
    
    subtitleText: {
        fontFamily: 'inter-regular',
        fontSize: '0.9rem',

    },
    section2: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 80,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    },
    EllipseSep: {
        height: 'auto',
        width: '100%',
        maxWidth: 14,
        margin: "0 120px 0 120px",
        [theme.breakpoints.down('sm')]: {
            margin: '40px 0px 40px 0px;'
        }
    },
    section3: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 120
    }
}))


const Home = () => {

    const classes = useStyles()
    const isConnected = useContext(isConnectedContext)

    if (isConnected !== connectStatus.disconnected) {
        return (
            <div className={classes.mainContainer}>
                <HeroSection />
                <span className={classes.subtitleText}>Stake Poor/ETH LP tokens & farm Poor</span>
                <section className={classes.section2} >
                    <Stake />
                    <img className={classes.EllipseSep} src={EllipseSep} alt="seperator" />
                    <Harvest />
                </section>

                <section className={classes.section3}>
                    <HarvestUnstakeButton />
                </section>
            </div>
        )
    } else {
        return <Redirect to={routes.connect} />
    }


}

export default Home