import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import { useLocation } from "react-router-dom";
import { routes } from '../../utils/constants'
import {useMediaQuery} from "@material-ui/core";


const FooterButton = withStyles({
    root: {
        fontFamily: 'Inter-regular',
        color: '#111111',
        maxWidth: 90,
        fontSize: '0.9rem',
        textTransform: 'capitalize',
        '&:hover': {
            fontFamily: 'Inter-bold',
            backgroundColor: 'transparent',
            fontSize: '0.85rem',

        }
    },
    ripple: {
        backgroundColor: 'blue'
    }
})(Button)


const useStyles = makeStyles({
    gridContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 120,
        marginBottom: 50,
    },
    footerItem: {
    }
})

const footerItem = [
    { name: 'Github', url: 'https://github.com/poorrug' },
    { name: 'Twitter', url: 'https://twitter.com/PoorRugOfficial' },
    { name: 'Telegram', url: 'https://t.me/poorRug' },
    { name: 'Uniswap', url: 'https://info.uniswap.org/pair/0xe0A0D928C95369912eE24cBE9178CfE7f8039C2c' },
    { name: 'Etherscan', url: 'https://etherscan.io/token/0x9d24b60d5eefe710348cc59173b166a256cbffcd' },
    { name: 'Medium', url: 'https://poorrug.medium.com/' }
]


const Footer = () => {

    const classes = useStyles()

    const onClickHandler = (url) => {
        window.location.assign(url)
    }
    const matches = useMediaQuery('(max-height: 717px)');
    const loc = useLocation();

    let styles;
    if (loc.pathname === routes.connect || loc.pathname === routes.upcoming){
        if (!matches){
            styles = { position: 'absolute', left: 0, bottom: 0 };
        } else {
            styles =  { position: 'unset', left: 'unset', bottom: 'unset' };
        }
    }

    return (

        <Grid container spacing={3} className={classes.gridContainer} style={styles}>

            {footerItem.map((item, idx) => (
                <Grid item key={idx} >
                    <FooterButton className={classes.footerItem}
                        variant='text'
                        onClick={() => onClickHandler(item.url)}
                        disableElevation>
                        {item.name}
                    </FooterButton>
                </ Grid>
            ))}

        </Grid>
    )
}

export default Footer