    
    
const getReward = async (stakingContract, account) => {

        try {
            await stakingContract.methods.getReward().send({
                from: account,
            });
            return true;
        } catch (err) {
            return false;
        }
    };

export default getReward;