import React, { useState, useEffect} from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from './pages/Home/Home.jsx'
import FAQ from './pages/FAQ/FAQ.jsx'
import Upcoming from './pages/Upcoming/Upcoming'
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header'
import PageNotFound from './pages/PageNotFound/PageNotFound'
import Connect from './pages/Connect/Connect'
import {UserInfoProvider} from './components/context/UserInfoContext'
import { makeStyles } from '@material-ui/core/styles';
import {routes} from './utils/constants'

import { useWallet } from 'use-wallet';
import BigNumber from "bignumber.js";
import web3 from "./contracts/web3";
import stakingContract from "./contracts/staking";
import stakingToken from "./contracts/stakingToken";
import poorRug from "./contracts/poorRug";

const useStyles = makeStyles((theme) => ({


    app:{
        textAlign: 'center',
            [theme.breakpoints.up("lg")]: {
            padding: '0px 140px 0 140px',
            textAlign: 'center'
        },
        [theme.breakpoints.down("sm")]: {
            padding: '0px 10px 0 10px',
            textAlign: 'center'
        
        }
    }
}))


const App = () => {

  const wallet = useWallet()
  const classes = useStyles()

  const [totalStaked, setTotalStaked] = useState("");
  const [message, setMessage] = useState("");
  const [userBalance, setUserBalance] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [earned, setEarned] = useState('');
  const [stakedAmount, setStakedAmount] = useState('');
  const [poorRugInPool, setPoorRugInPool] = useState('');
  const [poolEndTime, setpoolEndTime] = useState("");
  const [account, setAccount] = useState('');
  const [isConnected, setIsConnected] = useState(wallet.status);

  //flippers
  const [hasStaked, setHasStaked] = useState(false);
  const [hasUnstaked, setHasUnstaked] = useState(false);
  const [hasEarned, setHasEarned] = useState(false);
  const [hasExit, setHasExit] = useState(false);


    useEffect(() => {
    const updateStats = async () => {
        
        // Set Wallet Status
        setIsConnected(wallet.status)

        console.log('Approved Status', isApproved)

        //Get their account from metamask
        console.log(web3);
        const accounts = await web3.eth.getAccounts();
        setAccount(accounts[0]);

        // Require wallet to be connected uses UseWallet and not metamask
        console.log('IN USE EFGEVT', isApproved)
        if (accounts[0] && wallet.status === 'connected'){
            if (await stakingToken.methods.allowance(accounts[0], stakingContract.options.address).call() > 0){
                setIsApproved(true);
                console.log('Approved', isApproved)

            } else {
                setIsApproved(false);
                console.log('Not Approved', isApproved)

            }

            const [poolEndTime, earned, staked, userBalance, totalStaked, poorRugInPool] = await Promise.all([
                stakingContract.methods.periodFinish().call(),
                stakingContract.methods.earned(accounts[0]).call(),
                stakingContract.methods.balanceOf(accounts[0]).call(),
                stakingToken.methods.balanceOf(accounts[0]).call(),
                stakingContract.methods.totalSupply().call(),
                poorRug.methods.balanceOf(stakingContract.options.address).call()
            ])
  
            var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
            d.setUTCSeconds(poolEndTime);
            setpoolEndTime(d.toString());
  
            //amount of poorRugs a user has earned from staking
            let earnFormatted = new BigNumber(earned);
            setEarned(earnFormatted.shiftedBy(-18).toFormat(1));
  
            //Get number of stakingToken a user has staked

            BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_FLOOR });
            // equivalent

            console.log('YOU HAVE ACUTALLY STAKED: ', staked);
            setStakedAmount(new BigNumber(staked).shiftedBy(-18).toFormat(1));
  
            //Number of testfag a user has
            setUserBalance(new BigNumber(userBalance).shiftedBy(-18).toFormat(1));
  
            //Get total staked amount
            setTotalStaked(new BigNumber(totalStaked).shiftedBy(-18).toFormat(1));
  
            //get number of PoorRugs left in staking pool
            setPoorRugInPool(new BigNumber(poorRugInPool).shiftedBy(-18).toFormat(1));
        }


    };
        updateStats();

  }, [wallet.status, wallet.account, isApproved, hasStaked, hasUnstaked, hasEarned, hasExit]);
  
  const handleWalletConnect = () => {
      if (web3.givenProvider != null){
          wallet.connect();
      }
  }


  return (
    <UserInfoProvider account={account} isApproved={isApproved} setHasStaked={setHasStaked} hasStaked={hasStaked}
                      setIsApproved={setIsApproved} earned={earned} staked={stakedAmount} userBalance={userBalance} isConnected={isConnected}
                      hasUnstaked={hasUnstaked} setHasUnstaked={setHasUnstaked} setHasEarned={setHasEarned} hasEarned={hasEarned} setHasExit={setHasExit} hasExit={hasExit}
    >
        <Router>
            <div className={classes.app}> 
                <Header handleWalletConnect={handleWalletConnect}/>     
                <Switch>
                    <Route exact path={routes.home} component={Home} />
                    <Route path={routes.connect} component={Connect} />

                    <Route path={routes.faq} component={FAQ} />
                    <Route path={routes.upcoming} component={Upcoming} />
                    <Route path="*" component={PageNotFound} />
                </Switch>
                <Footer />
            </ div>
        </Router>
    </UserInfoProvider>

  )
}
export default App;