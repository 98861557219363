import BigNumber from "bignumber.js";
        
const stake = async (stakingContract, account, amount) => {
    if (amount > 0.01) {
        try {
            const valueToStake = amount.replace(/\,/g, '');
            const fixedNumber = valueToStake - 0.02;
            console.log(fixedNumber);
            console.log(BigNumber(fixedNumber).shiftedBy(18).toString());
            await stakingContract.methods.stake(BigNumber(fixedNumber).shiftedBy(18).toString()).send({
                from: account,
            });
            return true;
        } catch (err) {
            console.log(err);
            return false;
        }
    }
};

export default stake;