import React, { useContext } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { accountContext, earnedContext, isApprovedContext, hasEarnedContext, setHasEarnedContext } from '../context/UserInfoContext'

import Button from '@material-ui/core/Button'

import getReward from '../../services/getReward';
import stakingContract from "../../contracts/staking";

const HarvestButton = withStyles({
    root: {
        fontFamily: 'Inter-regular',
        backgroundColor: '#AF4C75',
        border: 'solid',
        borderColor: '#AF4C75',
        textTransform: 'capitalize',
        color: 'white',
        borderWidth: 2,
        width: '100%',
        height: 53,
        fontSize: '0.9rem',
        '&:hover': {
            backgroundColor: '#9B345F',
            borderColor: '#9B345F',

        }
    }
})(Button)

const useStyles = makeStyles({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 268,
        padding: 38,
        backgroundColor: '#00000008',
        borderRadius: 4,
        border: '1px solid #b04d76',
        boxShadow: '0 0 12px #AF4C75'
    },
    stakedTokens: {
        fontFamily: 'mikado',
        fontSize: '2.5rem',
        color: '#AF4C75'
    },
    stakedTokenText: {
        fontFamily: 'Inter-regular',
        fontSize: '0.9rem',
        color: '#AF4C75',
        marginTop: 20
    },
    stateButtons: {
        marginTop: 55,
        width: '70%',
        display: 'flex',
        justifyContent: 'center'
    }
})




const Harvest = () => {
    const classes = useStyles();
    const account = useContext(accountContext);
    const unharvestPoors = useContext(earnedContext);
    const isApproved = useContext(isApprovedContext);
    const hasEarned = useContext(hasEarnedContext);
    const setHasEarned = useContext(setHasEarnedContext);

    const styles = isApproved ? { backgroundColor: '#AF4C75', borderColor: '#AF4C75' } : { backgroundColor: '#DCB1C3', borderColor: '#DCB1C3', color: '#EFD8E1' }

    const onClickHandler = async (account) => {
        await getReward(stakingContract, account);
        setHasEarned(!hasEarned);
    }

    return (
        <div className={classes.mainContainer}>

            <span className={classes.stakedTokens}>
                {isApproved ? (unharvestPoors === '' ? '0.00' : unharvestPoors ) : '--'}
            </span>
            <span className={classes.stakedTokenText}>
                Unharvested Poors
            </span>
            <div className={classes.stateButtons}>
                <HarvestButton disabled={!isApproved} style={styles} variant="outlined" disableElevation onClick={() => onClickHandler(account)}>
                    Harvest
                </HarvestButton>
            </div>
        </div>
    )
}

export default Harvest