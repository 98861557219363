import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import useMediaQuery from '@material-ui/core/useMediaQuery';



const CardList = ({ styles, bulletImg, title, dataArray }) => {

    const { backgroundColor, color, boxShadow, borderRadius } = styles

    const useStyles = makeStyles((theme) => ({
        section1List1: {
            marginTop: 50
        },
        section1List1Container: {
            backgroundColor: backgroundColor,
            color: color,
            fontFamily: 'inter-regular',
            padding: '83px 40px 70px 40px',
            borderRadius: borderRadius,
            boxShadow: boxShadow,
            marginTop: 50,
            [theme.breakpoints.down('xs')]: {
                padding: '83px 20px 70px 20px',

            }

        },
        section1List1Title: {
            fontSize: '1.03rem',
            fontFamily: 'inter-bold',
            letterSpacing: '1.25px'
        },
        section1list1ItemContainer: {
            display: 'flex',
            marginTop: 41,
            alignItems: 'baseline',
            [theme.breakpoints.down('xs')]: {
                marginTop: 10
            }

        },
        section1List1Img: {
            width: 10,
            marginRight: 26
        },
        section1List1Items: {
            listStyle: 'none',
            lineHeight: 2

        },
    }))

    const classes = useStyles();
    // const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.down('sm'));


    return (
        <div className={classes.section1List1Container}>
            <span className={classes.section1List1Title}>{title}</span>
            <div className={classes.section1List1}>
                {dataArray.map((item, idx) => (
                    <div className={classes.section1list1ItemContainer} key={idx}>
                        <img className={classes.section1List1Img} src={bulletImg} alt="icon" />
                        <p className={classes.section1List1Items}>{item}</p>
                    </div>
                ))}


            </div>
        </div>
    )



}

export default CardList
