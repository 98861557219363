import BigNumber from "bignumber.js";
import web3 from "../contracts/web3";
        
const approve = async (stakingToken ,stakingContract, account) => {
    console.log(stakingContract);
    try {
        await stakingToken.methods.approve(stakingContract.options.address, web3.utils.toBN(2).pow(web3.utils.toBN(256)).sub(web3.utils.toBN(1))).send({
            from: account,
        });
        return true;
    } catch (err) {
        return false;
    }
};

export default approve;