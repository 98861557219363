import web3 from './web3.js';

const address = '0x9d24b60d5eefe710348cc59173b166a256cbffcd';

const abi = [
    {
        "anonymous":false,
        "inputs":[
            {
                "indexed":true,
                "internalType":"address",
                "name":"owner",
                "type":"address"
            },
            {
                "indexed":true,
                "internalType":"address",
                "name":"spender",
                "type":"address"
            },
            {
                "indexed":false,
                "internalType":"uint256",
                "name":"tokens",
                "type":"uint256"
            }
        ],
        "name":"Approval",
        "type":"event"
    },
    {
        "anonymous":false,
        "inputs":[
            {
                "indexed":true,
                "internalType":"address",
                "name":"owner",
                "type":"address"
            },
            {
                "indexed":false,
                "internalType":"uint256",
                "name":"tokens",
                "type":"uint256"
            }
        ],
        "name":"Collect",
        "type":"event"
    },
    {
        "anonymous":false,
        "inputs":[
            {
                "indexed":false,
                "internalType":"uint256",
                "name":"tokens",
                "type":"uint256"
            }
        ],
        "name":"Fee",
        "type":"event"
    },
    {
        "anonymous":false,
        "inputs":[
            {
                "indexed":true,
                "internalType":"address",
                "name":"owner",
                "type":"address"
            },
            {
                "indexed":false,
                "internalType":"uint256",
                "name":"tokens",
                "type":"uint256"
            }
        ],
        "name":"Stake",
        "type":"event"
    },
    {
        "anonymous":false,
        "inputs":[
            {
                "indexed":true,
                "internalType":"address",
                "name":"from",
                "type":"address"
            },
            {
                "indexed":true,
                "internalType":"address",
                "name":"to",
                "type":"address"
            },
            {
                "indexed":false,
                "internalType":"uint256",
                "name":"tokens",
                "type":"uint256"
            }
        ],
        "name":"Transfer",
        "type":"event"
    },
    {
        "anonymous":false,
        "inputs":[
            {
                "indexed":true,
                "internalType":"address",
                "name":"owner",
                "type":"address"
            },
            {
                "indexed":false,
                "internalType":"uint256",
                "name":"tokens",
                "type":"uint256"
            }
        ],
        "name":"Unstake",
        "type":"event"
    },
    {
        "anonymous":false,
        "inputs":[
            {
                "indexed":true,
                "internalType":"address",
                "name":"user",
                "type":"address"
            },
            {
                "indexed":false,
                "internalType":"bool",
                "name":"status",
                "type":"bool"
            }
        ],
        "name":"Whitelist",
        "type":"event"
    },
    {
        "constant":true,
        "inputs":[
            {
                "internalType":"address",
                "name":"_user",
                "type":"address"
            }
        ],
        "name":"allInfoFor",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"totalTokenSupply",
                "type":"uint256"
            },
            {
                "internalType":"uint256",
                "name":"totalTokensStaked",
                "type":"uint256"
            },
            {
                "internalType":"uint256",
                "name":"userBalance",
                "type":"uint256"
            },
            {
                "internalType":"uint256",
                "name":"userStaked",
                "type":"uint256"
            },
            {
                "internalType":"uint256",
                "name":"userDividends",
                "type":"uint256"
            }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
    },
    {
        "constant":true,
        "inputs":[
            {
                "internalType":"address",
                "name":"_user",
                "type":"address"
            },
            {
                "internalType":"address",
                "name":"_spender",
                "type":"address"
            }
        ],
        "name":"allowance",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
    },
    {
        "constant":false,
        "inputs":[
            {
                "internalType":"address",
                "name":"_spender",
                "type":"address"
            },
            {
                "internalType":"uint256",
                "name":"_tokens",
                "type":"uint256"
            }
        ],
        "name":"approve",
        "outputs":[
            {
                "internalType":"bool",
                "name":"",
                "type":"bool"
            }
        ],
        "payable":false,
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "constant":true,
        "inputs":[
            {
                "internalType":"address",
                "name":"_user",
                "type":"address"
            }
        ],
        "name":"balanceOf",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
    },
    {
        "constant":false,
        "inputs":[
            {
                "internalType":"address[]",
                "name":"_receivers",
                "type":"address[]"
            },
            {
                "internalType":"uint256[]",
                "name":"_amounts",
                "type":"uint256[]"
            }
        ],
        "name":"bulkTransfer",
        "outputs":[

        ],
        "payable":false,
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "constant":false,
        "inputs":[

        ],
        "name":"collect",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "payable":false,
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "constant":true,
        "inputs":[

        ],
        "name":"decimals",
        "outputs":[
            {
                "internalType":"uint8",
                "name":"",
                "type":"uint8"
            }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
    },
    {
        "constant":false,
        "inputs":[
            {
                "internalType":"uint256",
                "name":"_tokens",
                "type":"uint256"
            }
        ],
        "name":"distribute",
        "outputs":[

        ],
        "payable":false,
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "constant":true,
        "inputs":[
            {
                "internalType":"address",
                "name":"_user",
                "type":"address"
            }
        ],
        "name":"dividendsOf",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
    },
    {
        "constant":true,
        "inputs":[
            {
                "internalType":"address",
                "name":"_user",
                "type":"address"
            }
        ],
        "name":"isWhitelisted",
        "outputs":[
            {
                "internalType":"bool",
                "name":"",
                "type":"bool"
            }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
    },
    {
        "constant":true,
        "inputs":[

        ],
        "name":"name",
        "outputs":[
            {
                "internalType":"string",
                "name":"",
                "type":"string"
            }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
    },
    {
        "constant":false,
        "inputs":[
            {
                "internalType":"uint256",
                "name":"_tokens",
                "type":"uint256"
            }
        ],
        "name":"stake",
        "outputs":[

        ],
        "payable":false,
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "constant":false,
        "inputs":[
            {
                "internalType":"uint256",
                "name":"_tokens",
                "type":"uint256"
            }
        ],
        "name":"stakeDrop",
        "outputs":[

        ],
        "payable":false,
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "constant":true,
        "inputs":[
            {
                "internalType":"address",
                "name":"_user",
                "type":"address"
            }
        ],
        "name":"stakedOf",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
    },
    {
        "constant":true,
        "inputs":[

        ],
        "name":"symbol",
        "outputs":[
            {
                "internalType":"string",
                "name":"",
                "type":"string"
            }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
    },
    {
        "constant":true,
        "inputs":[

        ],
        "name":"totalStaked",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
    },
    {
        "constant":true,
        "inputs":[

        ],
        "name":"totalSupply",
        "outputs":[
            {
                "internalType":"uint256",
                "name":"",
                "type":"uint256"
            }
        ],
        "payable":false,
        "stateMutability":"view",
        "type":"function"
    },
    {
        "constant":false,
        "inputs":[
            {
                "internalType":"address",
                "name":"_to",
                "type":"address"
            },
            {
                "internalType":"uint256",
                "name":"_tokens",
                "type":"uint256"
            }
        ],
        "name":"transfer",
        "outputs":[
            {
                "internalType":"bool",
                "name":"",
                "type":"bool"
            }
        ],
        "payable":false,
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "constant":false,
        "inputs":[
            {
                "internalType":"address",
                "name":"_to",
                "type":"address"
            },
            {
                "internalType":"uint256",
                "name":"_tokens",
                "type":"uint256"
            },
            {
                "internalType":"bytes",
                "name":"_data",
                "type":"bytes"
            }
        ],
        "name":"transferAndCall",
        "outputs":[
            {
                "internalType":"bool",
                "name":"",
                "type":"bool"
            }
        ],
        "payable":false,
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "constant":false,
        "inputs":[
            {
                "internalType":"address",
                "name":"_from",
                "type":"address"
            },
            {
                "internalType":"address",
                "name":"_to",
                "type":"address"
            },
            {
                "internalType":"uint256",
                "name":"_tokens",
                "type":"uint256"
            }
        ],
        "name":"transferFrom",
        "outputs":[
            {
                "internalType":"bool",
                "name":"",
                "type":"bool"
            }
        ],
        "payable":false,
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "constant":false,
        "inputs":[
            {
                "internalType":"uint256",
                "name":"_tokens",
                "type":"uint256"
            }
        ],
        "name":"unstake",
        "outputs":[

        ],
        "payable":false,
        "stateMutability":"nonpayable",
        "type":"function"
    },
    {
        "constant":false,
        "inputs":[
            {
                "internalType":"address",
                "name":"_user",
                "type":"address"
            },
            {
                "internalType":"bool",
                "name":"_status",
                "type":"bool"
            }
        ],
        "name":"whitelist",
        "outputs":[

        ],
        "payable":false,
        "stateMutability":"nonpayable",
        "type":"function"
    }
]

export default new web3.eth.Contract(abi, address);