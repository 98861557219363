import React, { useState } from 'react';

const accountContext = React.createContext('');
const isApprovedContext = React.createContext(false);
const setIsApprovedContext = React.createContext();
const earnedContext = React.createContext('');

const hasEarnedContext = React.createContext('');
const setHasEarnedContext = React.createContext();

const stakedContext = React.createContext('');
const userBalanceContext = React.createContext('');
const isConnectedContext = React.createContext('');
const hasStakedContext = React.createContext(false);
const setStakedContext = React.createContext();

const hasUnstakedContext = React.createContext('');
const setHasUnstakedContext = React.createContext();

const hasExitContext = React.createContext('');
const setHasExitContext = React.createContext();


const UserInfoProvider = (props) => {

    const { children, account, isApproved, setIsApproved, earned, hasEarned, setHasEarned,  hasUnstaked, setHasUnstaked, hasExit,
        setHasExit, staked, userBalance, isConnected, hasStaked, setHasStaked } = props


    return (
        <accountContext.Provider value={account}>
            <isApprovedContext.Provider value={isApproved}>
                <setIsApprovedContext.Provider value={setIsApproved}>
                    <earnedContext.Provider value={earned} >
                        <stakedContext.Provider value={staked}>
                            <userBalanceContext.Provider value={userBalance}>
                                <isConnectedContext.Provider value={isConnected}>
                                    <hasStakedContext.Provider value={hasStaked}>
                                        <setStakedContext.Provider value={setHasStaked} >
                                            <hasEarnedContext.Provider value={hasEarned}>
                                                <setHasEarnedContext.Provider value={setHasEarned}>
                                                    <hasUnstakedContext.Provider value={hasUnstaked}>
                                                        <setHasUnstakedContext.Provider value={setHasUnstaked}>
                                                            <hasExitContext.Provider value={hasExit}>
                                                                <setHasExitContext.Provider value={setHasExit}>
                                                                     {children}
                                                                </setHasExitContext.Provider>
                                                            </hasExitContext.Provider>
                                                        </setHasUnstakedContext.Provider>
                                                    </hasUnstakedContext.Provider>
                                                </setHasEarnedContext.Provider>
                                            </hasEarnedContext.Provider>
                                        </setStakedContext.Provider>
                                    </hasStakedContext.Provider>
                                </isConnectedContext.Provider>
                            </userBalanceContext.Provider>
                        </stakedContext.Provider>
                    </earnedContext.Provider>
                </setIsApprovedContext.Provider>
            </isApprovedContext.Provider>
        </accountContext.Provider>
    )
}

export { UserInfoProvider, accountContext, isApprovedContext, setIsApprovedContext, earnedContext, stakedContext, userBalanceContext,
    isConnectedContext, hasStakedContext, setStakedContext, setHasEarnedContext, hasEarnedContext, setHasUnstakedContext, hasUnstakedContext, setHasExitContext, hasExitContext }

