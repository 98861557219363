import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MoneyIcon from '../../resources/svgs/money.svg'
import DiamondIcon from '../../resources/svgs/diamond.svg'
import SafeIcon from '../../resources/svgs/safe.svg'
import ShrinkIcon from '../../resources/svgs/minimize.svg'

const useStyles = makeStyles((theme) => ({

    gridContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 80
    },
    cardImgContainer: {
        width: 220,
        height: 220,
        backgroundColor: '#FEDFEC',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
        borderRadius: 4

    },
    img: {
        width: '47%'
    },
    text: {
        fontFamily: 'inter-bold',
        fontSize: '0.95rem',
        marginBottom: 20,

    },
    subText: {
        fontFamily: 'inter-regular',
        fontSize: '0.95rem',
        lineHeight: 2,
        maxWidth: '81%'
    },
    GridTitle: {
        fontSize: '1.03rem',
        fontFamily: 'inter-bold',
        letterSpacing: '1.25px',
    }

}))


const CardGrid = () => {

    const classes = useStyles()

    return (

        <div>
            <span className={classes.GridTitle}>3. What is PoorRug?</span>
            <Grid container spacing={3}>
                <Grid className={classes.gridContainer} item lg={6} md={6} sm={6} xs={12}>
                    <div className={classes.cardImgContainer}>
                        <img className={classes.img} src={MoneyIcon} alt='cashIcon' />
                    </div>
                    <span className={classes.text}>Ultimate Community Coin</span>
                    <p className={classes.subText}>
                        We serve to be the ultimate memecoin and home for all those who get rugpulled and scammed.
                        With no dev premine, this is the ultimate community coin. We have a lot of future plans currently
                        in development for Poor.
                </p>
                </Grid>
                <Grid className={classes.gridContainer} item lg={6} md={6} sm={6} xs={12}>
                    <div className={classes.cardImgContainer} style={{ backgroundColor: '#E3E3E3' }}>
                        <img className={classes.img} src={DiamondIcon} alt='cashIcon' />
                    </div>
                    <span className={classes.text}>NFTs</span>
                    <p className={classes.subText}>
                        NFTs will be created for those who stake ETH/Poor tokens and will be created
                        with a community owned treasury which will hold a number of crypto assets.
                </p>
                </Grid>
                <Grid className={classes.gridContainer} item lg={6} md={6} sm={6} xs={12}>
                    <div className={classes.cardImgContainer} style={{ backgroundColor: '#E3E3E3' }}>
                        <img className={classes.img} src={SafeIcon} alt='cashIcon' />
                    </div>
                    <span className={classes.text}>Vaults</span>
                    <p className={classes.subText}>
                        Vaults will be created to create yield farming strategies and that will auto buy $Poor
                        from Uniswap marketplace. Further reducing the supply of Poors, which in theory should
                        drive the price of Poor up.
                </p>
                </Grid>
                <Grid className={classes.gridContainer} item lg={6} md={6} sm={6} xs={12}>
                    <div className={classes.cardImgContainer} >
                        <img className={classes.img} src={ShrinkIcon} alt='cashIcon' />
                    </div>
                    <span className={classes.text}>Deflationary</span>
                    <p className={classes.subText}>
                        The Poor token is deflationary. Each transfer of $Poor
                        involves a 3% fee. 1% goes to the ETH/Poor liquidity pool, 1% goes to the treasury and 1% is burnt.
                </p>
                </Grid>
            </Grid>
        </div>
    )
}

export default CardGrid
