import React, { useContext } from 'react'
import { isConnectedContext } from '../../components/context/UserInfoContext'
import { Redirect } from "react-router-dom";



import { makeStyles } from '@material-ui/core/styles';
import HeroSection from '../../components/HeroSection/HeroSection'
import { connectStatus } from '../../utils/constants'


const useStyles = makeStyles({
    section1: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 100,
    },
    subtitleText: {
        fontFamily: 'inter-regular',
        fontSize: '0.9rem',
        textAlign: 'center'

    },
    text: {
        fontFamily: 'mikado',
        color: '#AF4C75',
        fontSize: '2rem'
    }
})


const Connect = (props) => {

    const classes = useStyles()
    const previousLoc = !props.location.state ? '/' : props.location.state;
    const isConnected = useContext(isConnectedContext)


    if (isConnected === connectStatus.disconnected) {
        return (
            <div className="home-container">
                <HeroSection />
                <span className={classes.subtitleText}>The home of all Rug Pulls</span>
                <section className={classes.section1} >
                    <span className={classes.text}>Please unlock your wallet</span>
                </section>
            </div>
        )
    } else {

        return <Redirect to={previousLoc} />
    }


}

export default Connect