import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Lottie from "react-lottie";
import animationData from "../../resources/lotties/digging.json";


const useStyles = makeStyles((theme) => ({
    lottie: {
        marginTop: 40
    },
    title: {
        fontFamily: 'mikado',
        justifyContent: 'center'
    },
}))


const Upcoming = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const classes = useStyles()


    return (
        <div>
            <div className={classes.lottie}>
            <Lottie
                options={defaultOptions}
                height={300}
                width={300}
            />
            </div>
            <h3 className={classes.title}>NFTs are currently being dug up</h3>
        </div>
    );
}

export default Upcoming