import React, { useContext } from 'react'
import { accountContext, isApprovedContext, hasExitContext, setHasExitContext } from '../context/UserInfoContext'


import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'

import stakingContract from "../../contracts/staking";
import exit from "../../services/exit";


const styles = theme => ({
    child: {
        backgroundColor: '#FC88B8'
    }
})

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Inter-regular',
        backgroundColor: '#111111',
        border: 'solid',
        borderColor: '#111111',
        textTransform: 'capitalize',
        color: 'white',
        borderWidth: 2,
        height: 53,
        width: 200,
        fontSize: '0.9rem',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#111111'

        }
    }
}))



const HarvestUnstakeButton = ({ classes }) => {

    const { ...rippleClass } = classes
    const styles = useStyles()
    const account = useContext(accountContext);
    const isApproved = useContext(isApprovedContext);
    const hasExit = useContext(hasExitContext);
    const setHasExit = useContext(setHasExitContext);

    const onClickHandler = async () => {
        await exit(stakingContract, account);
        setHasExit(!hasExit);
    }

    const isApprovedStyles = isApproved ? { backgroundColor: '#111111', color: 'white' } : { backgroundColor: 'transparent', color: '#D2D2D2' }

    return (
        <Button disabled={!isApproved} className={styles.root} style={isApprovedStyles} TouchRippleProps={{ classes: rippleClass }} variant="outlined" disableElevation onClick={onClickHandler}>
            Harvest & Unstake
        </Button>
    )
}

export default withStyles(styles)(HarvestUnstakeButton)