import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import BigNumber from "bignumber.js";


const UnstakeButton = withStyles({
    root: {
        fontFamily: 'Inter-regular',
        backgroundColor: '#AF4C75',
        border: 'solid',
        borderColor: '#AF4C75',
        textTransform: 'capitalize',
        color: 'white',
        borderWidth: 2,
        width: '100%',
        height: 53,
        fontSize: '0.9rem',
        '&:hover': {
            backgroundColor: '#9B345F',
            borderColor: '#9B345F',

        }
    }
})(Button)

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 200,
        [theme.breakpoints.down('xs')]: {
            marginTop: 40,
            padding: 38,
            backgroundColor: '#00000008',
            borderRadius: 4,
        }
    },
    stakedTokens: {
        fontFamily: 'mikado',
        fontSize: '2.5rem',
        color: '#111111',
        border: 0,
        width: '100%',
        textAlign: 'center',
        outline: 'none',
        '&:-webkit-appearance': 'none',
        margin: 0,
        'input[type=number]': {
            '-moz-appearance': 'textfield' /* Firefox */
        }
    },
    stakedTokenText: {
        fontFamily: 'Inter-regular',
        fontSize: '0.9rem',
        color: '#AF4C75',
        marginTop: 20
    },
    stakeButton: {
        marginTop: 55,
        width: '90%',
        display: 'flex',
        justifyContent: 'center'
    }
}))

const AmountToUnstake = ({ balance, UnstakeAll, unstakeOnClickHandler, unstakeAllHandler }) => {

    const classes = useStyles()
    const [unstakeAmount, setUnstakeAmount] = useState('')

    const handleOnChange = (event) => {

        let value = event.target.value
        // console.log('Value at handle on change', value, typeof(value))

        if (!value) {
            setUnstakeAmount('')
        }

        if (value) {
            setUnstakeAmount(value);
            if (UnstakeAll){
                unstakeAllHandler();
            }
        }

    }

    return (
        <div className={classes.mainContainer}>
            <input className={classes.stakedTokens} value={UnstakeAll ? balance : unstakeAmount} onChange={handleOnChange} type="string" placeholder="Enter" />
            <span className={classes.stakedTokenText}>
                Amount to unstake
            </span>
            <div className={classes.stakeButton}>
                {console.log(balance, typeof (balance))}
                <UnstakeButton onClick={() => unstakeOnClickHandler(UnstakeAll ? balance : unstakeAmount)} variant="outlined" disableElevation>
                    Unstake
                </UnstakeButton>
            </div>
        </div>
    )
}

export default AmountToUnstake