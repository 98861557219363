import React, { useContext } from 'react'
import { accountContext, setIsApprovedContext } from '../context/UserInfoContext'

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button'

import stakingToken from "../../contracts/stakingToken.js";
import stakingContract from "../../contracts/staking";
import approve from "../../services/approve";


const styles = theme => ({
    child: {
        backgroundColor: '#FC88B8'
    }
})

const useStyles = makeStyles({
    root: {
        fontFamily: 'Inter-regular',
        backgroundColor: '#111111',
        border: 'solid',
        borderColor: '#111111',
        textTransform: 'capitalize',
        color: 'white',
        borderWidth: 2,
        width: '100%',
        height: 53,
        fontSize: '0.9rem',
        '&:hover': {
            backgroundColor: 'transparent',
            color: '#111111'

        }
    }
})



const ApproveButton = ({ classes }) => {

    const { ...rippleClass } = classes
    const styles = useStyles()
    const account = useContext(accountContext)
    const setIsApproved = useContext(setIsApprovedContext)

    const onClickHandler = async () => {
        const result = await approve(stakingToken, stakingContract, account);
        setIsApproved(result)
    }

    return (
        <Button className={styles.root} TouchRippleProps={{ classes: rippleClass }} variant="outlined" disableElevation onClick={onClickHandler}>
            Approve
        </Button>
    )
}

export default withStyles(styles)(ApproveButton)