import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Lottie from "react-lottie";
import animationData from "../../resources/lotties/poorRug_title.json";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles({
    section1: {
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 20,
        marginTop: 60,
        marginBottom: 30
    },
    heroImg: {
        height: 'auto',
        width: '100%',
        maxWidth: 310,
        fontFamily: 'mikado'
    }
})

const HeroSection = () => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();

    const defaultOptions = {
        loop: true,
        autoplay: true,

        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    if (matches){
        return (
            <section className={classes.section1} >
                <Lottie
                    options={defaultOptions}
                    height={170}
                    width={240}
                />
            </section>
        )
    } else {
        return (
            <section className={classes.section1} >
                <Lottie
                    options={defaultOptions}
                    height={240}
                    width={340}
                />
            </section>
        )
    }

}

export default HeroSection