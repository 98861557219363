import React, { useState, useContext } from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ApproveButton from '../Buttons/ApproveButton'
import StakeDialog from '../Dialogs/StakeDialog'
import { stakedContext, isApprovedContext } from '../context/UserInfoContext'
import UnstakeDialog from '../Dialogs/UnstakeDialog'


const StakeButton = withStyles({
    root: {
        fontFamily: 'Inter-regular',
        borderColor: '#AF4C75',
        textTransform: 'capitalize',
        color: '#AF4C75',
        borderWidth: 2,
        width: '45%',
        height: 53,
        fontSize: '1.2rem',
        '&:hover': {
            backgroundColor: '#F8EFF2'
        }
    }

})(Button)


const UnstakeButton = withStyles({
    root: {
        fontFamily: 'Inter-regular',
        backgroundColor: '#AF4C75',
        border: 'solid',
        borderColor: '#AF4C75',
        textTransform: 'capitalize',
        color: 'white',
        borderWidth: 2,
        width: '45%',
        height: 53,
        fontSize: '1.2rem',
        '&:hover': {
            backgroundColor: '#9B345F',
            borderColor: '#9B345F',

        }
    }
})(Button)

const useStyles = makeStyles({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 268,
        padding: 38,
        backgroundColor: '#00000008',
        borderRadius: 4,
        border: '1px solid #b04d76',
        boxShadow: '0 0 12px #AF4C75'
    },
    stakedTokens: {
        fontFamily: 'mikado',
        fontSize: '2.5rem',
        color: '#AF4C75'
    },
    stakedTokenText: {
        fontFamily: 'Inter-regular',
        fontSize: '0.9rem',
        color: '#AF4C75',
        marginTop: 20
    },
    stateButtons: {
        marginTop: 55,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
    }
})


const Stake = () => {

    const [openStake, setOpenStake] = useState(false)
    const [openUnstake, setOpenUnstake] = useState(false)
    const isApproved = useContext(isApprovedContext);

    const staked = useContext(stakedContext)
    const classes = useStyles()

    const handleClickOpen = (type) => {

        if (type === 'stake') {
            setOpenStake(true)
        }
        else if (type === 'unstake') {
            setOpenUnstake(true)
        }
    }

    const handleClickClose = (type) => {

        if (type === 'stake') {
            setOpenStake(false)
        }
        else if (type === 'unstake') {
            setOpenUnstake(false)
        }
    }

    return (
        <div className={classes.mainContainer}>

            <span className={classes.stakedTokens}>
                {isApproved ? (staked === '' ? '0.00' : staked ) : '--'}
            </span>
            <span className={classes.stakedTokenText}>
                Staked Poor/ETH LP Tokens
            </span>

            {isApproved &&
                <div className={classes.stateButtons}>
                    <StakeButton variant="outlined" onClick={() => handleClickOpen('stake')} disableElevation>
                        +
                </StakeButton>

                    <UnstakeButton variant="outlined" onClick={() => handleClickOpen('unstake')} disableElevation>
                        -
                    </UnstakeButton>
                    <StakeDialog handleClose={handleClickClose} openRequest={openStake} />
                </div>
            }
            {!isApproved && <div className={classes.stateButtons}><ApproveButton /></div>}

            <UnstakeDialog handleClose={handleClickClose} openRequest={openUnstake} />

        </div>
    )
}

export default Stake
