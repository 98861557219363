import React, { useState, useContext } from 'react'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { accountContext, stakedContext, hasUnstakedContext, setHasUnstakedContext } from '../context/UserInfoContext'

import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import ExitIcon from '../../resources/svgs/exit-button.svg'
import PoorYouHaveStaked from '../PoorYouHave/PoorYouHaveStaked'
import AmountToUnstake from '../AmountToUnstake/AmountToUnstake'

import unstake from '../../services/unstake';
import stakingContract from '../../contracts/staking'

import Lottie from "react-lottie";
import animationData from "../../resources/lotties/steak.json";

const DialogBox = withStyles((theme) => ({
    paper: {
        [theme.breakpoints.up('lg')]: {
            width: '40%',
            padding: 30
        },
        [theme.breakpoints.down('lg')]: {
            width: '40%',
            padding: 30
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 30
        }
    }
}))(Dialog)

const useStyles = makeStyles((theme) => ({
    exitButton: {
        width: 0,
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center'
    },
    title: {
        marginTop: 30,
        fontFamily: 'mikado',
        fontSize: '1.2rem',
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },
    graphicContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 50,
        marginLeft: 50
    },
    graphic: {
        height: 'auto',
        width: '100%',
        maxWidth: 160,
    },
    mainSection: {
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: 90,
        marginBottom: 50,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'center'
        }
    }
}))

const UnstakeDialog = ({ handleClose, openRequest }) => {

    const classes = useStyles()
    const theme = useTheme()
    const fullscreen = useMediaQuery(theme.breakpoints.down('sm'))
    const staked = useContext(stakedContext)


    const [unstakeAll, setUnstakeAll] = useState(false);
    const account = useContext(accountContext);
    const hasUnstaked = useContext(hasUnstakedContext);
    const setHasUnstaked = useContext(setHasUnstakedContext);
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const unstakeAllHandler = () => {
        setUnstakeAll(!unstakeAll)
        console.log('POOR BALANCE WHEN STAKE HIT', staked)
    }

    const unstakeOnClickHandler = async (amountToUnstake) => {
        if (parseFloat(staked) >= parseFloat(amountToUnstake)){
            console.log('staked', staked, 'amountToUnstake', amountToUnstake);
            await unstake(stakingContract, account, amountToUnstake);
            setHasUnstaked(!hasUnstaked);
        }
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    let lottie;
    if (matches){
        lottie = <Lottie
            options={defaultOptions}
            height={120}
            width={240}
        />
    }else {
        lottie = <Lottie
            options={defaultOptions}
            height={200}
            width={300}
        />
    }


    return (
        <DialogBox fullScreen={fullscreen} onClose={() => handleClose('unstake')} open={openRequest} >
            <IconButton className={classes.exitButton} onClick={() => handleClose('unstake')} disableTouchRipple>
                <img src={ExitIcon} alt="exit" />
            </IconButton>

            <section className={classes.titleContainer}>
                <span className={classes.title}>How many Poor/ETH LP tokens do you want to unstake?</span>
            </section>

            <section className={classes.graphicContainer}>
                {lottie}
            </section>
            <section className={classes.mainSection}>
                <PoorYouHaveStaked unstakeAll={unstakeAll} balance={staked} unstakeAllHandler={unstakeAllHandler} />
                <AmountToUnstake unstakeAllHandler={unstakeAllHandler} balance={staked} UnstakeAll={unstakeAll} unstakeOnClickHandler={unstakeOnClickHandler} />
            </section>

        </DialogBox >


    )
}

export default UnstakeDialog