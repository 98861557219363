import React, { useState, useContext } from 'react';
import { Link, withRouter, useLocation } from 'react-router-dom'
import Logo from '../../resources/svgs/poorrug-logo.svg';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import { isConnectedContext, accountContext } from '../context/UserInfoContext'
import { connectStatus, routes } from '../../utils/constants'

const WalletButton = withStyles({
    root: {
        backgroundColor: '#FC88B8',
        color: 'white',
        fontFamily: 'Inter-regular',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#AF4C75'
        }
    }
})(Button)

const StyledTabs = withStyles({

    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        height: 5,
        fontSize: '0.935rem',
        '& > span': {
            maxWidth: 45,
            width: '100%',
            backgroundColor: '#111111',
            borderRadius: 5
        }
    }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#111111',
        minWidth: '90px',
        fontFamily: 'Inter-regular',
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles({
    navBar: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',

    },
    logoImg: {
        width: 27,
        marginTop: 8,
        marginRight: 8
    },
    logoTitle: {
        fontFamily: 'mikado',
        marginBot: 80
    },
    drawerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '80vw'
    },
    drawerText: {
        fontFamily: 'mikado',
        fontSize: '1.8rem',
        textDecoration: 'none',
        color: '#111111',
        marginTop: 24

    }


})


const Header = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const loc = useLocation()

    const { handleWalletConnect } = props
    const [value, setValue] = useState(0);
    const [openDrawer, setopenDrawer] = useState(false)
    let connectionStatus = useContext(isConnectedContext)
    let account = useContext(accountContext)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleDrawer = () => {
        setopenDrawer(!openDrawer)
    }

    const isConnected = connectionStatus === connectStatus.connected

    const handleOnClick = (route) => {
        setValue(0);
        window.location.href = route;
    }

    if (matches) {
        return (
            <div className={classes.navBar}>
                <div className="logo">
                    <img className={classes.logoImg} src={Logo} alt="wajok smile" />
                </div>
                <WalletButton disabled={isConnected} onClick={handleWalletConnect} variant="contained" disableElevation>
                    {isConnected ? !account ? connectStatus.connecting : account.substring(0, 8) : "Unlock Wallet"}
                </WalletButton>
                <IconButton onClick={handleDrawer} disableTouchRipple>
                    <MenuIcon />
                </IconButton>
                <SwipeableDrawer anchor="right" open={openDrawer} onOpen={handleDrawer} onClose={handleDrawer}>
                    <div className={classes.drawerContainer}>
                        <Link className={classes.drawerText} to={routes.home} onClick={handleDrawer} style={loc.pathname === routes.home ? { color: '#AF4C75' } : { color: '#111111' }}>Home</Link>
                        <Link className={classes.drawerText} to={routes.faq} onClick={handleDrawer} style={loc.pathname === routes.faq ? { color: '#AF4C75' } : { color: '#111111' }}>FAQ</Link>
                        <Link className={classes.drawerText} to={routes.home} onClick={() => handleOnClick(routes.upcoming)} style={loc.pathname === routes.upcoming ? { color: '#AF4C75' } : { color: '#111111' }}>Ruggies</Link>
                        <Link className={classes.drawerText} to={routes.home} onClick={() => handleOnClick(routes.liquidity)}>Liquidity</Link>
                    </div>
                </SwipeableDrawer>
            </div>
        )

    } else {
        return (
            <div className={classes.navBar}>
                <div className="logo">
                    <img className={classes.logoImg} src={Logo} alt="wajok smile" />
                    <span className={classes.logoTitle}>
                        PoorRug
                    </span>
                </div>

                <StyledTabs value={value} onChange={handleChange}>
                    <StyledTab component={Link} to={routes.home} label="Home" />
                    <StyledTab component={Link} to={routes.faq} label="FAQ" />
                    <StyledTab component={Link} to={routes.home} label="Ruggies" onClick={() => handleOnClick(routes.upcoming)}/>
                    <StyledTab component={Link} to={routes.home} label="Liquidity" onClick={() => handleOnClick(routes.liquidity)} />
                </StyledTabs>

                <WalletButton disabled={isConnected} onClick={handleWalletConnect} variant="contained" disableElevation>
                    {isConnected ? !account ? connectStatus.connecting : account.substring(0, 5) + "..." + account.substring(38, 42) : "Unlock Wallet"}
                </WalletButton>
            </div>
        )
    }


}

export default withRouter(Header)