
const connectStatus = {
    connected: 'connected',
    disconnected: 'disconnected',
    connecting: 'connecting',
}

const routes = {
    connect: '/connect',
    home: '/',
    faq: '/faq',
    upcoming: 'https://nfts.poorrug.com',
    liquidity: "https://app.uniswap.org/#/add/0x9d24b60d5eefe710348cc59173b166a256cbffcd/ETH"
}


export {connectStatus, routes}