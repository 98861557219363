import BigNumber from "bignumber.js";

const unstake = async (stakingContract, account, amount) => {
    try {
        const valueToUnstake = amount.replace(/\,/g,'');
        const fixedNumber = valueToUnstake - 0.02;
        console.log('staking contract fixed num' ,fixedNumber);
        await stakingContract.methods.withdraw(BigNumber(fixedNumber).shiftedBy(18).toString()).send({
            from: account,
        });
        return true;
    } catch (err) {
        console.log(err);
        return false;
    }
};

export default unstake;