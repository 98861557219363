import React, { useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'



const StakeAllButton = withStyles({
    root: {
        fontFamily: 'Inter-regular',
        backgroundColor: 'transparent',
        border: 'solid',
        borderColor: '#AF4C75',
        textTransform: 'capitalize',
        color: '#AF4C75',
        borderWidth: 2,
        width: '100%',
        height: 53,
        fontSize: '0.9rem',
        '&:hover': {
            borderColor: 'transparent',
        }
    }
})(Button)

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 200,
        [theme.breakpoints.down('xs')]: {
            padding: 38,
            backgroundColor: '#00000008',
            borderRadius: 4,
        }
    },
    poorTokens: {
        fontFamily: 'mikado',
        fontSize: '2.5rem',
        color: '#111111'
    },
    poorTokenText: {
        fontFamily: 'Inter-regular',
        fontSize: '0.9rem',
        color: '#AF4C75',
        marginTop: 20
    },
    stakeAllButton: {
        marginTop: 55,
        width: '90%',
        display: 'flex',
        justifyContent: 'center'
    }
}))

const PoorYouHave = ({ balance, stakeAllHandler, stakeAll }) => {

    const classes = useStyles()

    const onClickHandler = () => {
        stakeAllHandler()
    }

    return (
        <div className={classes.mainContainer}>
            <span className={classes.poorTokens}>
                {balance === '' ? '0.00' : balance}
            </span>
            <span className={classes.poorTokenText}>
                Poor/ETH LP you have
            </span>
            <div className={classes.stakeAllButton}>
                <StakeAllButton variant="outlined" style={stakeAll ? { color: 'white', backgroundColor: '#AF4C75' } : { color: '#AF4C75', backgroundColor: 'transparent' }} onClick={onClickHandler} disableElevation>
                    Stake All
                </StakeAllButton>
            </div>
        </div>
    )
}

export default PoorYouHave